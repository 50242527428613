import * as Yup from "yup";

export const init = (field, email) => {
  if (field === "login") {
    return {
      email: "",
      password: "",
    };
  }
  if (field === "earlyAccess") {
    return {
      name: "",
      email: "",
      upload_file: "",
    };
  }
  if (field === "media") {
    return {
      email: "",
    };
  }
  if (field === "signup") {
    return {
      name: "",
      podcastName: "",
      email: "",
      password: "",
    };
  }
  if (field === "newSignup") {
    return {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      isAgree: false,
    };
  }
  if (field === "oldsignup") {
    return {
      email: email || "",
      password: "",
      confirmPassword: "",
    };
  }
  if (field === "forgotInit") {
    return {
      email: "",
    };
  }
  if (field === "forgot") {
    return {
      password: "",
      confirmPassword: "",
    };
  }
};

export const schema = (field) => {
  if (field === "login") {
    return {
      email: Yup.string().email("Invalid").required("required"),
      password: Yup.string().required("required"),
    };
  }
  if (field === "media") {
    return {
      email: Yup.string().email("Invalid").required("required"),
    };
  }
  if (field === "forgotInit") {
    return {
      email: Yup.string().email("Invalid").required("required"),
    };
  }
  if (field === "forgot") {
    return {
      password: Yup.string().required("required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    };
  }
  if (field === "earlyAccess") {
    return {
      email: Yup.string().email("Invalid").required("required"),
      name: Yup.string().required("required"),
      upload_file: Yup.mixed().required("File is required"),
    };
  }
  if (field === "signup") {
    return {
      name: Yup.string().required("required"),
      email: Yup.string().email("Invalid").required("required"),
      password: Yup.string().required("required"),
    };
  }
  if (field === "newSignup") {
    return {
      firstName: Yup.string().required("required"),
      lastName: Yup.string().required("required"),
      email: Yup.string().email("Invalid").required("required"),
      password: Yup.string().required("required"),
    };
  }
  if (field === "oldsignup") {
    return {
      email: Yup.string().email("Invalid").required("required"),
      password: Yup.string().required("required"),
      confirmPassword: Yup.string().required("required"),
    };
  }
};

export const earlyAccessFieldsMap = {
  label: {
    name: "First Name ",
    email: "E-mail ",
    upload_file: "Upload File ",
  },
  placeholder: {
    name: "Enter your first name",
    email: "Enter your email ",
    upload_file: "Select transcript file",
  },
};

export const ForgotFieldMap = {
  forgotInit: {
    email: "Email address",
  },
  forgot: {
    password: "New password",
    confirmPassword: "Retype new password",
  },
};

export const loginFieldMap = {
  label: {
    email: "Email",
    password: "Password",
  },
  placeholder: {
    email: "Email address",
    password: "Enter your Password",
  },
  type: {
    email: "text",
    password: "password",
  },
};

export const signupFields = {
  label: {
    name: "First Name ",
    podcastName: "Podcast Name",
    email: "Email ",
    password: "Password ",
  },
  placeholder: {
    name: "Enter your full name",
    podcastName: "Enter your podcast name",
    email: "Enter email",
    password: "Create  a Password ",
  },
};

export const newSignupFields = {
  label: {
    firstName: "First Name",
    lastName: "Last Name ",
    email: "Email",
    password: "Password",
  },
  placeholder: {
    firstName: "Your first name",
    lastName: "Your last name",
    email: "Email address",
    password: "Enter your password",
  },
  type: {
    firstName: "text",
    lastName: "text",
    email: "text",
    password: "password",
  },
};

export const FieldMap = {
  email: "Email",
  password: "Password",
  confirmPassword: "Confirm Password",
};

export const createPhoneNo = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
};

export const AccountTagline = (isLogin) => {
  if (isLogin) {
    return "Please Login";
  }
  return "Create an account to save all your deciphrs in one place.";
};

export const TAB = {
  0: "audio",
  1: "transcript",
};
