import React from "react";

import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { TextField, Typography } from "@mui/material";

const TextFieldStyled = styled(TextField)({
  marginTop: 0,
  marginBottom: "8px",
  input: {
    width: "100%",
    background: "#FFFFFF !important",
    borderRadius: "12px",
  },
  ".MuiInputAdornment-root": {
    marginRight: "0px",
  },

  ".MuiFormHelperText-root": {
    marginLeft: "20px",
    padding: 0,
  },

  ".MuiInputBase-root": {},
  fieldset: {
    borderRadius: "12px",
    padding: "0",
  },
  "@media(max-width:900px)": {
    input: {
      marginLeft: "0px",
    },
    fieldset: {
      borderRadius: "12px",
    },
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: "12px",
    '& fieldset': {
      borderColor: "#DCE0E5",
    },
    '&.Mui-focused fieldset': {
      borderColor: "#DCE0E5",
    },
  },
  width: "100%",
});

const AuthInput = ({ label, name, placeholder, formik, type }) => {
  return (
    <Box
      sx={{
        mb: 3,
        mt: label ? "-4px" : 3,
        "& > .css-9wy24x-MuiInputBase-root-MuiInput-root:before": {
          borderBottom: "none  !important",
        },
        "& .css-9wy24x-MuiInputBase-root-MuiInput-root:after": {
          borderBottom: "none  !important",
        },
        my: "18px",
        flex: 1,
      }}
    >
      {label && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20.3px",
            letterSpacing: "0.2px",
            color: "#10181E",
            mb: "5px",
          }}
        >
          <label htmlFor={name}>
            {label}
            {name === "podcastName" ? "" : "*"}
          </label>
        </Typography>
      )}

      <TextFieldStyled
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        sx={{
          my: 1,
          width: "100%",
        }}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        variant="outlined"
      />
    </Box>
  );
};

export default AuthInput;
