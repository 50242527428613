import { history } from "./history";
import { signup, login, forgotInit, forgot, verifyEmail } from "./apis";

const redirectOnLogin = (data, referrer) => {
  if (referrer) {
    return history.navigate(referrer);
  }

  if (data.org_role) {
    // DAI Editor
    history.navigate("/dashboard");
  } else {
    // DAI
    history.navigate("/library");
  }
};

export const loginApi = async (payload, referrer, callback, errorHandler) => {
  let authData;

  try {
    authData = await login({
      email: payload.email,
      password: payload.password,
      batch: localStorage.getItem("batch") || "gym_a",
    });

    localStorage.setItem("userInfo", JSON.stringify(authData));
    redirectOnLogin(authData, referrer);
    callback(authData);
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const forgotInitApi = async (payload, callback, errorHandler) => {
  try {
    await forgotInit({
      email: payload.email,
    });
    callback();
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const forgotApi = async (payload, callback, errorHandler) => {
  try {
    await forgot(payload);
    callback();
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const signupApi = async (payload, callback, errorHandler) => {
  try {
    const authData = await signup({
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
      password: payload.password,
      batch: localStorage.getItem("batch") || "gym_a",
      podcast_name: payload.podcastName || "",
    });

    localStorage.setItem("userInfo", JSON.stringify(authData));

    callback(authData);
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const VerifyEmail = async (payload, callback, errorHandler) => {
  try {
    await verifyEmail(payload);
    callback();
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};
