import { useContext, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { LoadingButton } from "@mui/lab";
import { GlobalData } from "../../context";
// import { getUserInfo } from "store/user";
import { init, schema, newSignupFields } from "../../utils/constants";
import { analytics } from "../../utils/GTM";
import { signupApi } from "../../utils/authSeq";
import AuthInput from "./components/authInput";

function NewSignUp() {
  const [currentUser, setCurrentUser] = useState();
  const [loader, setLoader] = useState(false);
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);
  const history = useNavigate();
  // const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: init("newSignup"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("newSignup")),
    onSubmit: (value) => SubmitHandler(value),
  });

  const SubmitHandler = async (value) => {
    setLoader(true);
    await signupApi(
      value,
      (authData) => {
        history("/library");
        setCurrentUser(authData.user);
        // dispatch(getUserInfo());
        setSeverity("success");
        openAlert();
        setMessage(
          "Signed up successfully. Please check your email for an activation link."
        );

        analytics.track("User Successfully Signed up");
        analytics.identify(authData.user.localId, {
          email: authData.user.email,
          name: authData.user.displayName,
        });
        formik.resetForm();
      },
      errorHandler
    );
    setLoader(false);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "45%" },
        background: "white",
        backgroundColor: "transparent",
        py: { xs: "100px", md: "calc(102px + 2vh)" },
        minHeight: { xs: "100vh", lg: "unset" },
        paddingBottom: "50px",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#10181E",
            marginBottom: { xs: "12px", lg: "0" },
          }}
        >
          Get Started
        </Typography>
        <Typography
          sx={{
            color: "#6E757C",
            fontSize: { xs: "14px", lg: "16px" },
            fontWeight: "400",
            lineHeight: "24px",
            margin: "24px 0",
          }}
        >
          Already have an account?{" "}
          <Link
            to="/auth/login"
            style={{
              color: "#0659F9",
              textDecoration: "none",
              fontWeight: "700",
            }}
          >
            Login
          </Link>
          .
        </Typography>
      </Box>

      <Divider sx={{ my: "20px" }}/>

      <Box sx={{}}>
        {Object.keys(formik.initialValues)
          .filter((key) => key !== "isAgree")
          .map((v) => {
            return (
              <AuthInput
                label={newSignupFields.label[v]}
                placeholder={newSignupFields.placeholder[v]}
                type={newSignupFields.type[v]}
                formik={formik}
                name={v}
                key={v}
              />
            );
          })}
      </Box>
      <FormControlLabel
        style={{
          margin: "0",
        }}
        control={
          <Checkbox
            icon={
              <RadioButtonUncheckedIcon
                sx={{
                  background: "#fff",
                  borderRadius: "50%",
                }}
              />
            }
            checkedIcon={
              <CheckIcon
                sx={{
                  background: "#fff",
                  borderRadius: "50%",
                  color: "#0659F9",
                  padding: "3px",
                }}
              />
            }
            defaultChecked={formik?.initialValues?.isAgree}
            id="isAgree"
            sx={{
              color: "#6A7986",
              "&.Mui-checked": {
                color: "#0659F9",
              },
            }}
          />
        }
        sx={{
          mb: "24px",
          width: "100%",
        }}
        label={
          <Typography
            sx={{
              fontFamily: "HelveticaNowDisplay",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#6A7986",
              "& > a": {
                textDecoration: "underline",
                color: "#6A7986",
              },
            }}
          >
            I acknowledge the{" "}
            <a
              rel="noreferrer"
              href="https://www.deciphr.ai/terms-and-conditions"
              target="_blank"
            >
              {"T&Cs"}
            </a>{" "}
            and{" "}
            <a
              rel="noreferrer"
              href="https://www.deciphr.ai/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </Typography>
        }
        id="isAgree"
        onChange={formik.handleChange}
      />
      <Box sx={{ marginTop: "32px", width: "100%" }}>
        <LoadingButton
          sx={{
            background: "#0659F9",
            borderRadius: "44px",
            color: "#fff",
            height: "56px",
            width: "100%",
            opacity: formik.values.isAgree ? 1 : 0.5,
            curson: formik.values.isAgree ? "pointer" : "not-allowed",

            "&:hover": {
              background: "#0659F9",
              color: "#fff",
              boxShadow: formik.values.isAgree
                ? "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                : "none",
            },
          }}
          disableElevation
          onClick={() => {
            if (!formik.values.isAgree) {
              return;
            }
            formik.handleSubmit();
          }}
          loading={loader}
        >
          Sign Up
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default NewSignUp;
