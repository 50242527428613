import { useContext, useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AuthInput from "./components/authInput";
import { GlobalData } from "../../context";
import { init, schema, loginFieldMap } from "../../utils/constants";
import { loginApi } from "../../utils/authSeq";
// import { getUserInfo } from "../../store/user";
import { analytics } from "../../utils/GTM";
import EmailIcon from "../../assets/email.svg";
import PasswordIcon from "../../assets/password.svg";

const IconMap = {
  email: EmailIcon,
  password: PasswordIcon,
};

const NewLogin = () => {
  const [currentUser, setCurrentUser] = useState();
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(null);
  // const dispatch = useDispatch();
  const location = useLocation();
  const { openAlert, setMessage, setSeverity, setLogin } =
    useContext(GlobalData);
  const formik = useFormik({
    initialValues: init("login"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("login")),
    onSubmit: (value) => SubmitHandler(value),
  });

  const SubmitHandler = async (value) => {
    setLoader(true);

    await loginApi(
      value,
      location.state?.referrer,
      (authData) => {
        setLogin(true);
        setCurrentUser(authData.user);
        // dispatch(getUserInfo());

        analytics.track("User Successfully Logged in");
        analytics.identify(authData.user.localId, {
          email: authData.user.email,
          name: authData.user.displayName,
        });
      },
      errorHandler
    );

    setLoader(false);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };


  useEffect(() => {
    if (location.state && location.state.message) {
      setAlert(location.state.message);
    }
  }, [location]);

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "45%" },
        background: "white",
        backgroundColor: "transparent",
        py: { xs: "100px", md: "calc(102px + 2vh)" },
        minHeight: { xs: "100vh", lg: "unset" },
        paddingBottom: "50px",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#10181E",
            // marginBottom: { xs: "12px", lg: "0" },
            marginBottom: "24px",
          }}
        >
          Welcome Back
        </Typography>

        <Typography
          sx={{
            color: "#6E757C",
            fontSize: { xs: "14px", lg: "16px" },
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          Don’t have an account? {" "}
          <Link
            to="/auth/signup"
            style={{
              color: "#0659F9",
              textDecoration: "none",
              fontWeight: "700",
            }}
          >
            Sign up
          </Link>
          .
        </Typography>
      </Box>

      <Divider sx={{ my: "30px" }}/>


      {alert !== null && (
        <Box
          sx={{
            p: "12px 16px",
            m: "15px auto",
            bgcolor: "#EF466F",
            borderRadius: "12px",
          }}
        >
          <Typography
            role="alert"
            sx={{
              color: "#FFF",
              fontSize: "15px",
              fontWeight: 600,
            }}
          >
            {alert}
          </Typography>
        </Box>
      )}

      <form name="loginForm" onSubmit={formik.handleSubmit}>
        <Box>
          {Object.keys(formik.initialValues).map((v) => (
            <AuthInput
              label={loginFieldMap.label[v]}
              placeholder={loginFieldMap.placeholder[v]}
              type={loginFieldMap.type[v]}
              formik={formik}
              name={v}
              key={v}
              icon={IconMap[v]}
            />
          ))}

          <Link
            to="/auth/forgot"
            style={{
              fontSize: "14px",
              fontWeight: "700",
              width: { xs: "100%", sm: "100%", md: "100%" },
              textAlign: "right",
              color: "#0659F9",
              textDecoration: "none",
            }}
          >
            Forgot password?
          </Link>
        </Box>

        <Box sx={{ marginTop: "32px", width: "100%" }}>
          <LoadingButton
            sx={{
              background: "#0659F9",
              borderRadius: "44px",
              color: "#fff",
              height: "56px",
              width: "100%",

              "&:hover": {
                background: "#0659F9",
                color: "#fff",
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            }}
            type="submit"
            disableElevation
            loading={loader}
          >
            Login
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default NewLogin;
