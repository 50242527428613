import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/dashboardNav/indexV2";
import bgImg from "../../assets/mascot.png";

const AuthV2 = () => {
  const urLocation = useLocation();
  const [isConfirmEmail, setIsConfirmEmail] = useState(true);

  useEffect(() => {
    if (urLocation.search.split("&").length > 1) {
      urLocation.search.split("&").forEach((v) => {
        if (v.includes("mode")) {
          setIsConfirmEmail(v.replace("?mode=", "") === "verifyEmail");
        }
      });
    } else {
      setIsConfirmEmail(false);
    }
  }, [urLocation]);

  if (isConfirmEmail) {
    return <Outlet />;
  }

  return (
    <Box
      sx={{
        maxWidth: "1920px",
        margin: "auto",
        display: "flex",
        justifyContent: { xs: "center", lg: "unset" },
        padding: { xs: "0 50px", sm: "0 100px", lg: "0 11%", xl: "0 11%" },
      }}
    >
      <Navbar logoSize="md" />

      <Box sx={{ width: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthV2;
