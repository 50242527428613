import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GlobalData } from "../../context";
import { init, schema, ForgotFieldMap } from "../../utils/constants";
import { forgotInitApi } from "../../utils/authSeq";
import AuthInput from "./components/authInput";
import EmailIcon from "../../assets/email.svg";

const IconMap = {
  email: EmailIcon,
};

const ForgotPassword = () => {
  const [loader, setLoader] = useState(false);
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);
  const formik = useFormik({
    initialValues: init("forgotInit"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("forgotInit")),
    onSubmit: (value) => SubmitHandler(value),
  });


  const SubmitHandler = async (value) => {
    setLoader(true);
    await forgotInitApi(
      value,
      () => {
        sucessHandler("Password reset request sent. Please check your email!");
      },
      errorHandler
    );
    setLoader(false);
  };

  const sucessHandler = (res) => {
    setSeverity("success");
    openAlert();
    setMessage(res);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "45%" },
        background: "white",
        backgroundColor: "transparent",
        py: { xs: "100px", md: "calc(102px + 2vh)" },
        minHeight: { xs: "100vh", lg: "unset" },
        paddingBottom: "50px",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "24px",
            color: "#10181E",
            marginBottom: { xs: "12px", lg: "0" },
          }}
        >
          Forgot your password?
        </Typography>
        <Typography
          sx={{
            color: "#6E757C",
            fontSize: { xs: "14px", lg: "16px" },
            fontWeight: "400",
            lineHeight: "24px",
            margin: "24px 0",
          }}
        >
          Enter your email address to reset your password. You may need to check
          your spam folder or unblock noreply@remoatteams.com
        </Typography>
      </Box>

      <Divider sx={{ my: '20px' }} />

      <Box sx={{ my: 3 }}>
        {Object.keys(formik.initialValues).map((v) => (
          <AuthInput
            placeholder={ForgotFieldMap.forgotInit[v]}
            formik={formik}
            name={v}
            key={v}
            icon={IconMap[v]}
          />
        ))}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <LoadingButton
          sx={{
            background: "#0659F9",
            borderRadius: "44px",
            color: "#fff",
            height: "56px",
            width: "100%",

            "&:hover": {
              background: "#0659F9",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          }}
          disableElevation
          onClick={formik.handleSubmit}
          loading={loader}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
