import axios from "./axiosConfig";

export const signup = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v1/signup",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const login = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const forgotInit = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login/reset-password-link",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const forgot = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login/confirm-reset-password",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const verifyEmail = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/signup/confirm-email",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveTranscript = async (payload, setProgress, transcript) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "v1/up/save",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        const uploadedMB = `${(progressEvent.loaded / 1024 / 1024).toFixed(
          2
        )} MB of ${(transcript.size / 1024 / 1024).toFixed(2)} MB`;
        setProgress(uploadedMB);
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveAudio = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/s2t/transcribe-user-audio",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTranscripts = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/all",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFilesList = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/v2/up/all",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadTranscripts = async (id, ext) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/download/${id}/${ext}`,
      responseType: "blob",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAudioTranscripts = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/all-audio",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const sendOpenReq = async (docId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/status/" + docId,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUnprocessedFileIds = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/unprocessed",
    });

    return data.unprocessed || [];
  } catch (e) {
    return Promise.reject(e);
  }
};

export const triggerProcessing = async (id) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/up/process-doc-insight/${id}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInsights = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/details/${id}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const SetTemplate = async (template, docId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/update-audio-template/${template}/${docId}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const DelInsights = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/delete/${id}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadTrans = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/generic-process/upload-transcript`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resendVerification = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/signup/resend-verification-email`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const uploadSample = async (sample) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/dummy/sample/" + sample,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const transcriptDataForEditor = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/edit/" + id,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const savetranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/up/save-transcript/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveSpeaker = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/up/update-speakers/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveInitSpeaker = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/s2t/add-speakers/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addSpeakerlist = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/up/speaker/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const delSpeakerList = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: "/up/speaker/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSpeaker = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/up/update-speaker/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateTranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/up/transcript/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const replaceAllTranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/up/transcript/replace/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSummaryChapters = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/up/processed_data/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAudiogramBackground = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/audiogram/backgrounds",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateAudiogram = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v1/audiogram/from-quotes/" + id,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateAudiogramBackground = async (docId, bgId) => {
  try {
    await axios({
      method: "POST",
      url: `/audiogram/update-background/${docId}/${bgId}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getVideoUploadLink = async (filename) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/video_transcription/upload_url?file_name=${encodeURIComponent(
        filename
      )}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadVideo = async (url, payload, setProgress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");

    xhr.upload.onprogress = (progressEvent) => {
      const uploadedMB = `${(progressEvent.loaded / 1024 / 1024).toFixed(
        2
      )} MB of ${(payload.size / 1024 / 1024).toFixed(2)} MB`;
      setProgress(uploadedMB);
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => {
      reject(new Error("Network error"));
    };

    xhr.send(payload);
  });
};

export const startTranscription = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/video_transcription/transcribe-user-video`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAudioUploadLink = async (filename) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/s2t/upload_url?file_name=${encodeURIComponent(filename)}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const startAudioTranscription = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/v1/s2t/transcribe-user-audio`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadTranscript = async ({ docId, queryParams }) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/v1/up/download/${docId}`,
      params: queryParams,
      responseType: "blob",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exportContent = async ({ docId, params }) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/v2/up/export/${docId}`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchUserInfo = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/user/profile",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserInfo = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/user/profile",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const changePassword = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/user/password",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getVerificationEmail = async (payload) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/user/email-verification",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateArticle = async ({
  docId,
  inspiration,
  context,
  type,
  format,
  tone,
}) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/article/generate/${docId}`,
      headers: {
        "Content-Type": "application/json",
      },

      data: {
        inspiration,
        context,
        type,
        format,
        tone,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkArticalStatus = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/status/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getArticle = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/article/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllArticles = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const payment = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v1/payment/subscribe",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const userInfo = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/v1/user/profile",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const applyPromoCode = async (code, plan) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/payment/coupon",
      data: {
        coupon_code: code,
        plan,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
